
export default {
	name: "CraDetails",
	data() {
		return {
			sex: "girl",
			newEdit: false,
			name: "",
			years: "",
			list: "",
			potjectList: [],
			education: "",
			major: "",
			age: "",
			proCount: "",
			inCount: "",
			img: "",
			user: {},
			craId: '',
			fraction: [], //综合评分
			ggligtScoreDetail: "", //机构伦理沟通
			sqfmydScoreDetail: "", //申办方满意度
			xmzgpfScoreDetail: "", //项目主管评分
			yjzxtScoreDetail: "", //研究者协调
			yyglScoreDetail: "", //药品管理
			refStatistics: [],
			dailyStatistics: [],
			CRAPharmacyInfo: []
		};
	},

	mounted() {
		this.drawLine();
	},
	created() {
		this.token = this.getCookie("token");
		this.craId = this.$route.params.id
		if (this.craId) {
			this.getCrcParticulars();
			this.getCrcParticulars1();
			this.getCRAJCInfo();
			this.getCRAAuditInfo()
			this.getCRAPharmacyInfo()
		}
	},
	methods: {
		// 获取负责项目
		getCrcParticulars() {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + '/getCRAProject', {
					craId: _this.craId
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.potjectList = res.data.data;
					} else if (res.data.code == 401) {
						_this.$router.push('/')
					}
				});
		},
		toCrcMena() {
			this.$router.go(-1)
		},
		toPro(id) {
			this.$router.push({
				name: "projectParticulars",
				params: {
					id: id
				}
			});
		},
		// 或者五角评价值
		drawLine() {
			var _this = this;
			// 基于准备好的dom，初始化echarts实例
			// let echar1 = this.$echarts.init(document.getElementById("echar-1"))
			let echar = this.$echarts.init(document.getElementById("echar"));
			echar.setOption({
				radar: {
					//						        shape: 'circle',
					center: ["50%", "50%"],
					radius: "50%",
					name: {
						textStyle: {
							color: "#333"
						}
					},
					splitArea: {
						areaStyle: {
							color: "#D7F6FB"
						}
					},
					indicator: [{
						name: "职业素养",
						max: 100
					},
					{
						name: "沟通与协调",
						max: 100
					},
					{
						name: "学习与接受",
						max: 100
					},
					{
						name: "整理与完善",
						max: 100
					},
					{
						name: "执行与完成",
						max: 100
					}
					],
					axisLine: {
						lineStyle: {
							color: "#fff"
						}
					},
					splitLine: {
						lineStyle: {
							color: "#fff"
						}
					}
				},
				series: {
					type: "radar",
					// areaStyle: {normal: {}},
					data: [{
						value: [
							//   response.data.data.user.avgGgligt,
							//   response.data.data.user.avgSqfmyd,
							//   response.data.data.user.avgXmzgpf,
							//   response.data.data.user.avgYjzxt,
							//   response.data.data.user.avgYygl
							100,
							100,
							50,
							60,
							70
						],
						// value: [10, 30, 70, 90, 32],
						areaStyle: {
							normal: {
								color: "#5BD0E2"
							}
						},
						itemStyle: {
							normal: {
								color: "#5BD0E2"
							}
						}
					}]
				}
			});
			// 绘制图表
			//   _this.$http
			//     .post(
			//       "/API/PRO/selectCRCUserScore",
			//       {
			//         CRCUserId: this.$route.params.id
			//       },
			//       {
			//         headers: {
			//           token: _this.token
			//         }
			//       }
			//     )
			//     .then(function(response) {
			//       if (response.data.code == 200) {

			//       }
			//     });
		},
		tableRowClassName({
			row,
			rowIndex
		}) {
			if (rowIndex === 1) {
				return "warning-row";
			} else if (rowIndex === 3) {
				return "success-row";
			}
			return "";
		},
		// 获取用户信息
		getCrcParticulars1() {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/getCRAInfo", {
					craId: _this.craId
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.user = response.data.data;
						_this.ggligtScoreDetail = response.data.data.ggligtScoreDetail;
						_this.sqfmydScoreDetail = response.data.data.sqfmydScoreDetail;
						_this.xmzgpfScoreDetail = response.data.data.xmzgpfScoreDetail;
						_this.yjzxtScoreDetail = response.data.data.yjzxtScoreDetail;
						_this.yyglScoreDetail = response.data.data.yyglScoreDetail;
					}
				});
		},
		// 监查能力统计
		getCRAJCInfo() {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/getCRAJCInfo", {
					craId: _this.craId
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						var arr = []
						var arr1 = res.data.data.ortherObj
						var arr2 = res.data.data.firstObj
						arr1.unshift(arr2)
						_this.dailyStatistics = arr1
					}
				});
		},
		// 稽查能力统计
		getCRAAuditInfo() {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/getCRAAuditInfo", {
					craId: _this.craId
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						var arr = []
						var arr1 = res.data.data.ortherObj
						var arr2 = res.data.data.firstObj
						arr1.unshift(arr2)
						_this.refStatistics = arr1
					}
				});
		},
		// 药政核查能力统计
		getCRAPharmacyInfo() {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/getCRAPharmacyInfo", {
					craId: _this.craId
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						var arr = []
						var arr1 = res.data.data.ortherObj
						var arr2 = res.data.data.firstObj
						arr1.unshift(arr2)
						_this.CRAPharmacyInfo = arr1
					}
				});
		}
	},
	filters: {
		setUserIdNumber: function (val) {
			if (val) {
				return val.substr(0, 14) + "****";
			}
		}
	}
};
